<template>
    <r-e-dialog :title="type==='Water'?'修改水表号':'修改电表号'" class="layer-contract-detail" :visible.sync="dialogVisible" show-footer top="10vh" width="600px"
            @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
        <el-form ref="formPublish" label-width="100px" size="small" :model="hydropowerData" :rules="rules">
            <div class="flex">
                <el-form-item label="房源信息">
                    <el-input v-model="equipmentData.name" style="width: 400px;" disabled />
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item :label="type==='Water'?'水表号':'电表号'" prop="num">
                    <el-input v-model="hydropowerData.num" style="width: 400px;" />
                </el-form-item>
            </div>
        </el-form>
    </r-e-dialog>
</template>

<script>
    import {setHydropowerNum} from "@/api/door-management";
    import {MessageInfo, MessageError, MessageSuccess} from "@custom/message";

    export default {
        name: "dialog-update-hydropower-num",
        data() {
            return {
                dialogVisible: false,
                hydropowerData: {},
                rules: {
                    num: [{required: true, message: '表号不能为空', trigger: 'blur'}]
                },
                type: ""
            };
        },
        props: {
            equipmentData: {
                type: Object,
                default: () => ({})
            }
        },
        methods: {
            openDialog(type) {
                this.type = type;
                this.dialogVisible = true;
                let {uuid} = this.equipmentData;
                this.hydropowerData.uuid = uuid;
                this.hydropowerData.code = type === 'Water' ? 500502 : 500501;
            },
            clickSubmit() {
                let that = this;
                this.$refs["formPublish"].validate((valid) => {
                    if (valid) {
                        const loading = this.$loading({lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)'});
                        let {uuid, code, num} = that.hydropowerData;
                        setHydropowerNum({uuid, code, num}).then(res => {
                            this.$emit('getHydropower', res.info);
                            MessageSuccess('修改表号成功');
                            loading.close();
                            that.clickCancel();
                        }).catch(err => {
                            loading.close();
                        });
                    }
                })
            },
            clickCancel() {
                this.dialogVisible = false;
            },
        },
    }
</script>

<style scoped>

</style>